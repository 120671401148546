<template>
  <div class="box-page">
    <div class="form-filter" :style="{paddingTop: '10px',paddingRight: '15px'}">
      <a-form ref="formRef" layout="horizontal" :model="formState">
        <a-row :gutter="24">
          <a-col :span="6">
            <a-form-item label="主叫号码" name="telA">
              <a-input v-model:value="formState.telA" placeholder="请输入主叫号码"/>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="被叫号码" name="telB">
              <a-input v-model:value="formState.telB" placeholder="请输入被叫号码"/>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="创建时间" name="valueTime">
              <a-config-provider :locale="zhCN">
                <a-range-picker v-model:value="formState.valueTime"/>
              </a-config-provider>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="命中关键词" name="keyword">
              <a-input v-model:value="formState.keyword" placeholder="请输入命中关键词"/>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item has-feedback label="识别结果">
              <el-select v-model="formState.translatedResult" placeholder="请选择风险等级" style="width: 100%" clearable
                         filterable>
                <el-option v-for="(item,index) in [{label:'成功',value:0},{label:'失败',value:1}]" :key="index"
                           :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item has-feedback label="是否命中">
              <el-select v-model="formState.hitTypeStatus" placeholder="请选择命中结果" style="width: 100%" clearable
                         filterable>
                <el-option
                    v-for="(item,index) in [{label:'全部',value:''},{label:'未命中',value:0},{label:'命中',value:1}]"
                    :key="index"
                    :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </a-form-item>
          </a-col>
          <a-col :span="12" :style="{ textAlign: 'right' }">
            <a-button type="primary" @click="handelSearch"> 查询</a-button>
            <a-button :style="{ marginLeft: '8px' }" @click="handelReset"> 重置</a-button>
            <!-- <a-button :style="{ marginLeft: '8px'}" type="link" @click="isOutlined = !isOutlined">
              {{isOutlined ? '展开' : '收起'}}
              <UpOutlined v-if="isOutlined" />
              <DownOutlined v-else />
            </a-button> -->
          </a-col>
        </a-row>
      </a-form>
      <!--      <a-row>-->
      <!--        <a-col :span="24" :style="{ textAlign: 'right' }">-->
      <!--          <a-button type="primary" @click="handelSearch"> 查询</a-button>-->
      <!--          <a-button :style="{ marginLeft: '8px' }" @click="handelReset"> 重置</a-button>-->
      <!--          &lt;!&ndash; <a-button :style="{ marginLeft: '8px'}" type="link" @click="isOutlined = !isOutlined">-->
      <!--            {{isOutlined ? '展开' : '收起'}}-->
      <!--            <UpOutlined v-if="isOutlined" />-->
      <!--            <DownOutlined v-else />-->
      <!--          </a-button> &ndash;&gt;-->
      <!--        </a-col>-->
      <!--      </a-row>-->
    </div>
    <div class="table-list" style="padding-top: 15px">
      <el-table class="eltable" v-loading="loading" ref="multipleTable" :data="tableData" tooltip-effect="dark"
                style="width: 100%" :header-cell-style="{background:'#e0e5ff',color:'#24252F'}" row-key="id" size="mini"
                :row-style="{height:'50px'}" :cell-style="{padding:'0px'}" default-expand-all
                :tree-props="{children: 'children', hasChildren: 'hasChildren'}" :row-class-name="tableRowClassName">
        <el-table-column label="昵称" width="150" prop="nickName">
        </el-table-column>
        <el-table-column label="主叫号码" width="100" prop="telA">
        </el-table-column>
        <el-table-column label="被叫号码" width="100" prop="telB">
        </el-table-column>
        <!-- <el-table-column label="状态" prop="hitTypeStatusText">
        </el-table-column> -->
        <el-table-column label="识别结果" prop="translatedResultText">
        </el-table-column>
        <el-table-column label="命中类型/关键字" width="180">
          <template #default="scope">
            <el-tooltip placement="top">
              <template #content>
                <p class="ellipsis">
                  <b>类型:</b> {{ scope.row.hitType == null || scope.row.hitType == '' ? '-' : scope.row.hitType }}
                </p>
                <p class="ellipsis">
                  <b>关键字:</b>
                  {{ scope.row.hitTypeDesc == null || scope.row.hitTypeDesc == '' ? '-' : scope.row.hitTypeDesc }}
                </p>
              </template>
              <div>
                <p class="ellipsis">
                  <b>类型:</b> {{ scope.row.hitType == null || scope.row.hitType == '' ? '-' : scope.row.hitType }}
                </p>
                <p class="ellipsis">
                  <b>关键字:</b>
                  {{ scope.row.hitTypeDesc == null || scope.row.hitTypeDesc == '' ? '-' : scope.row.hitTypeDesc }}
                </p>
              </div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column label="话术/语音内容" width="200" prop="translatedContent">
          <template #default="{row}">
            <p class="ellipsis-line3" @click="quickConfirm(row)" v-html="row.translatedContent.join('')"/>
          </template>
        </el-table-column>
        <el-table-column width="200" :label="item.name" :prop="item.prop" v-for="(item,index) in columnList"
                         :key="index">
        </el-table-column>
        <el-table-column label="质检日期" width="200" prop="createTime">
        </el-table-column>
        <el-table-column label="对话时长" prop="duration">
        </el-table-column>
        <el-table-column label="操作" width="150" fixed="right">
          <template #default="scope">

            <span class="button-font-color" @click="handleRowClick(scope.row)">
              <icon-font type="icon-caidanguanli"/>
              详情
            </span>

          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pager" style="padding-top: 8px">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                     :current-page="pageForm.currentPage" :page-size="pageForm.pageSize" background
                     :page-sizes="[10, 20, 30, 40]"
                     layout="total, sizes, prev, pager, next, jumper" :total="pageForm.total">
      </el-pagination>
    </div>
    <recordDialog ruleType="api" :getParams="getParams()" :audit="false"
                  :isAdminUser="isAdminUser" @nextData="handleRowClick" ref="record"
                  v-model="recordVisible" :rowJson="rowJson" @success="recordVisible = false"/>
  </div>
</template>

<script>
import {reactive} from 'vue'
import _apiRuleService from '@/api/open/APIRule'
import {DownOutlined, UpOutlined} from '@ant-design/icons-vue'
import moment from 'moment'
import _lineService from '@/api/open/LineQualityInspections'
import zhCN from 'ant-design-vue/es/locale/zh_CN'
import _aSRabilitys from "@/api/open/ASRabilitys";
import syemt from '@/views/open/ASRabilitys/component/syemt.vue'
import user from '@/views/open/ASRabilitys/component/user.vue'
import {PageModule} from "@/views/open/common/pagination";
import recordDialog from "@/views/open/LineQualityInspections/record-dialog.vue";
import voiceCheck from "@/api/open/voiceCheck/voiceCheck";
import deepClone from "@/utils/deepClone";

export default {
  name: "api-rule",
  components: {
    // DownOutlined,
    // UpOutlined,
    recordDialog,
  },
  props: {
    isAdminUser: {
      type: String,
      default: () => ''
    }
  },
  data() {
    return {
      translatedResult: 0,
      loading: false,
      tableData: [],
      isOutlined: false,
      formState: reactive(new FormModel()),
      pageForm: new PageModule(),
      columnList: [],
      recordVisible: false,
      rowJson: {},
      zhCN: zhCN,
    }
  },
  created() {
    this.getCheckList()
  },
  // computed:{
  //
  // },
  methods: {
    tableRowClassName({row}) {
      if (row.read === 1 || row.auditStatus === 1) {
        return 'el-tab-row-primary-light'
      } else if (row.auditStatus === 2) {
        return 'el-tab-row-error'
      } else if (row.auditStatus === 3) {
        return 'el-tab-row-warning'
      } else if (row.auditStatus === 4) {
        return 'el-tab-row-warning'
      }
      return ''
    },
    async getNextTableData(index) {
      let res;
      if (index == 1) {
        //下一个
        let findIndex = this.tableData?.findIndex(item => item.id == this.rowJson.id);
        // console.log('handleRowClick aaa', findIndex, this.tableData.length,)
        if (findIndex !== -1) {
          if (findIndex < this.tableData.length - 1) {
            res = this.tableData[findIndex + 1];
          } else {
            //加载下一页
            await this.handleCurrentChange(this.pageForm.currentPage + 1);
            res = this.tableData[0];
          }
        }
      } else if (index == -1) {
        //上一个
        let findIndex = this.tableData?.findIndex(item => item.id == this.rowJson.id);
        if (findIndex !== -1) {
          if (findIndex != 0) {
            res = this.tableData[findIndex - 1];
          } else if (this.pageForm.currentPage > 1) {
            await this.handleCurrentChange(this.pageForm.currentPage - 1);
            res = this.tableData[this.tableData.length - 1];
          }
        }
      } else if (index == 0) {
        //当前行
        res = this.tableData?.find(item => item.id == this.rowJson.id);
      }
      return res;
    },
    updateTableData(id, values) {
      //更新属性值
      this.$nextTick(() => {
        let findLine = this.$refs.multipleTable.data.find(
            (item) => item.id == id
        )
        if (findLine) {
          for (let prop in values) {
            findLine[prop] = deepClone(values[prop]);
          }
        }
      })
    },
    // 将一条质检记录标记为已读(暂未刷新统计数)
    async quickConfirm(row) {
      // if (row.read == 1) {
      //   return
      // }
      // let res = await _lineService._recordService.getRead({id: row.id})
      // if (res.code == 200) {
      // if (!row.read) {
      //   this.updateTableData(row.id, {read:1});
      // }
      // }
    },
    async handleRowClick(row, column, cell, event, values) {
      if (values) {
        //更新当前行数据
        let current = await this.getNextTableData(0);
        if (current) {
          this.updateTableData(current.id, values);
        }
      }
      if (row == 1 || row == -1) {
        row = await this.getNextTableData(row);
      }
      if (!row) {
        //最后一页最后一条，没有更多了
        return;
      }
      if (!row.read) {
        // if (this.isAdminUser === 'system') {
        //   await _lineService._recordService.getSMMRead({ id: row.id })
        // } else {
        //   await _lineService._recordService.getRead({ id: row.id })
        // }
        await _lineService._recordService.getRead({id: row.id})
      }

      this.rowJson = row
      this.recordVisible = true
      if (!row.read) {
        this.updateTableData(row.id, {read: 1});
      }
      this.$nextTick(() => {
        this.$refs.record.open()
      })
    },
    handleSizeChange(val) {
      this.pageForm.setPageSize(val)
      this.getList()
    },
    handleCurrentChange(val) {
      this.pageForm.setCurrentPage(val)
      this.getList()
    },
    async getList() {
      const res = await _apiRuleService._apiRuleService.getList(
          this.getParams()
      )
      this.tableData = res?.data?.records?.map((item) => {
        return {...item, ...new TableItem(item)}
      })||[]
      this.pageForm.setTotal(res.total)
    },
    getParams() {
      return {
        ...this.pageForm.getParamsJson(),
        ...this.formState.getParamsJson(),
      }
    },
    handelSearch() {
      this.pageForm.init()
      this.getList()
    },
    async getCheckList() {
      const res = await _apiRuleService._apiRuleService.getCheckList()
      // const str = '{"cmTime":"自定义时间","cmV1":"自定义字段1","cmV2":"自定义字段2","cmV3":"自定义字段3","cmV4":"自定义字段4","cmV5":"自定义字段5","cmV6":"自定义字段6"}'
      const obj = JSON.parse(res.data.optionValue)
      // const obj = JSON.parse(str)
      for (const item in obj) {
        this.columnList.push({
          name: obj[item],
          prop: item,
        })
      }
      this.getList()
    },
    handelReset() {
      this.formState.translatedResult = 0
      this.$refs.formRef.resetFields()
      this.handelSearch()
    },
  },
}

const now = new Date()
const year = now.getFullYear()
const n = now.getMonth() + 1
const d = now.getDate()
const str = `${year}-${n > 9 ? n : '0' + n}-${d > 9 ? d : '0' + d}`

/**
 * "page":1,  # 必传  当前页
 "pageSize":10,   # 必传  每页页数
 "telA":"18856283218",  # 主叫(非必选)
 "telB":"15255468972",  # 被叫(非必选)
 "createTimeBegin":"2022-08-10 00:00:00",   # 创建开始时间(非必填)
 "createTimeEnd":"2022-08-10 23:59:59",     # 创建结束时间(非必填)
 "businessType":"",                         #  行业 (非必选)
 "translatedResult":0,                      # 识别结果(0:成功/1:失败) (非必选)
 "translatedContent":"平安",                # 识别内容(非必选)
 "cmTimeBegin":"2022-08-08 11:05:05",       # 自定义开始时间(非必选)
 "cmTimeEnd":"2022-08-08 11:07:05",         # 自定义结束时间(非必选)
 "cmV1":"测试",                              # 自定义字段1 (非必选)
 "cmV2":"快乐",                              # 自定义字段2 (非必选)
 "cmV3":"假的吧",                             # 自定义字段3 (非必选)
 "cmV4":"如",                                 # 自定义字段4 (非必选)
 "cmV5":"100",                                # 自定义字段5 (非必选)
 "cmV6":"15"                                  # 自定义字段6 (非必选)
 */
class FormModel {
  constructor() {
    this.telA = ''
    this.telB = ''
    this.valueTime = [
      moment(str + ' 00:00:00', 'YYYY-MM-DD HH:mm:ss'),
      moment(str + ' 23:59:59', 'YYYY-MM-DD HH:mm:ss'),
    ]
    this.keyword = ''
    this.translatedResult = 0
    this.hitTypeStatus = 1
  }

  getParamsJson() {
    return {
      telA: this.telA,
      telB: this.telB,
      keyword: this.keyword,
      createTimeBegin:
          this.valueTime && this.valueTime[0]
              ? this.valueTime[0].format('YYYY-MM-DD') + ' 00:00:00'
              : '',
      createTimeEnd:
          this.valueTime && this.valueTime[1]
              ? this.valueTime[1].format('YYYY-MM-DD') + ' 23:59:59'
              : '',
      api: 1,
      translatedResult: this.translatedResult,
      hitTypeStatus: this.hitTypeStatus
    }
  }
}

/**
 * "cmTime": "2022-08-08 11:05:05",     # 自定义时间
 "hitTypeStatus": 0,                  #分类是否命中(0:未命中/1:命中)
 "type": 1,                           # 类型(0:质检/1:识别)
 "hitRuleStatus": 0,                  #规则是否命中(0:未命中/1:命中)
 "bindId": null,                      # 绑定id
 "duration": 16,                      # 录音时长(秒)
 "uid": 100008,                       # 用戶id
 "hitRule": null,                     # 命中规则名称
 "hitType": null,                     # 命中分类名称
 "hitTypeDesc": null,                 # 命中分类描述
 "price": 0.0011,                     # 质检价格
 "company": "duofang",                # 公司名称
 "id": "1557273909403115521",
 "callId": "1557273908388093953",     # callId
 "cmV6": "15",                        # 自定义字段6
 "nickName": "数字云自测账户1",        #  用户名称
 "recordUrl": "http://112.21.190.133:65080/3616195868.wav",  # 录音地址
 "minutes": 1,                        # 分钟数
 "cmV4": "如果",                       # 自定义字段4
 "cmV5": "100",                        # 自定义字段5
 "translatedResult": 0,                #识别结果(0:成功/1:失败)
 "cmV2": "快乐",                        # 自定义字段2
 "cmV3": "假的吧",                     # 自定义字段3
 "updateTime": "2022-08-10 15:53:33",
 "telX": null,                         # 中间号
 "cmV1": "测试",                        # 自定义字段1
 "subId": "1557273908388093953",       # 绑定id
 "telA": "18856283218",                # 主叫
 "telB": "15255468972",                 # 被叫
 "amountId": 0,                         # 服务商id
 "createTime": "2022-08-10 15:53:27",    # 创建时间
 "hitRuleLevel": null,
 "interfaceId": 0,
 "translatedContent": "{\"payload\":{\"status_code\":21050000,\"request_time\":1660118008248,\"sentences\":[{\"silence_duration\":0,\"end_time\":1390,\"speech_rate\":131,\"begin_time\":20,\"text\":\"喂你好\",\"channel_id\":0,\"emotion_value\":7.3},{\"silence_duration\":0,\"end_time\":2750,\"speech_rate\":73,\"begin_time\":1930,\"text\":\"对\",\"channel_id\":0,\"emotion_value\":7.1},{\"silence_duration\":0,\"end_time\":15010,\"speech_rate\":332,\"begin_time\":3270,\"text\":\"哎，你好，哎，你好，是这样的哦，我这边是中国平安个贷部的平安，推出小微企业主，经营100万额度利息最低4厘，所以想了解一下您，最近有资金方面的需求吗？\",\"channel_id\":0,\"emotion_value\":7.1},{\"silence_duration\":0,\"end_time\":16460,\"speech_rate\":352,\"begin_time\":15100,\"text\":\"不需要好吧，谢谢啊\",\"channel_id\":0,\"emotion_value\":5.9}],\"biz_duration\":16480,\"task_id\":\"852c63e6188111edb9157b8b25d7908c\"
                ,\"status_text\":\"SUCCESS\",\"solve_time\":1660118013378}}",  #识别内容
 "businessType": null,                   # 行业
 "hitRuleDesc": null,
 "taskId": "852c63e6188111edb9157b8b25d7908c"     # 任务id
 */
class TableItem {
  constructor(item) {
    this.nickName = item.nickName
    this.telA = item.telA
    this.telX = item.telX
    this.telB = item.telB
    this.hitTypeStatus = item.hitTypeStatus
    this.hitTypeStatusText = this.getHitTypeStatusText(item.hitTypeStatus)
    this.hitType = item.hitType
    this.businessType = item.businessType
    this.translatedResult = item.translatedResult
    this.translatedResultText = this.getTranslatedResultText(
        item.translatedResult
    )
    this.createTime = item.createTime
    this.duration = item.duration
    this.hitTypeDesc = item.hitTypeDesc
    this.translatedContent = this.getTranslatedContent(item.translatedContent)
  }

  getHitTypeStatusText(val) {
    if (val == 0) {
      return '正常'
    } else if (val == 1) {
      return '禁用'
    }
    return '--'
  }

  // 识别结果(0:成功/1:失败)
  getTranslatedResultText(val) {
    if (val == 0) {
      return '成功'
    } else if (val == 1) {
      return '失败'
    }
    return '--'
  }

  getTranslatedContent(val) {
    const obj = JSON.parse(val)
    if (obj && obj.payload && obj.payload.sentences) {
      const getKeyword = (keyword) =>
          `<b class="keyword-tag" style="color:#ff0000;">${keyword}</b>`
      if (!!this.hitTypeDesc) {
        this.hitTypeDesc.split(',').map((item) => {
          obj.payload.sentences.forEach(
              (item) =>
                  (item.text = item.text.replace(
                      item.keyword,
                      getKeyword(item.keyword)
                  ))
          )
        })
      }
      return obj.payload.sentences.map((item) => item.text)
    }
    return ['-']
  }

  // JSON.parse(item.translatedContent)?.payload?.sentences
}

</script>

<style lang="less" scoped>

.box-page /deep/ .el-table .cell {
  overflow: hidden;
  white-space: nowrap; //禁止换行
  text-overflow: ellipsis; //...
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.eltable :deep(.el-table__body) {
  .el-tab-row-error {
    background: #fcbebe;
  }

  .el-tab-row-warning {
    background: #f3dd94;
  }

  .el-tab-row-success {
    background: #b2edd9;
  }

  .el-tab-row-primary-light {
    background: #F0F5FF;
  }
}
</style>