import { Request } from '@/http/request'

class ApiRuleService {
    // 列表查询
    async getList (params) {
        const res = await Request.axiosInstance.post('/open/es-user-check/list', params)
        return res;
    }
    /**
     * "optionType":"voiceCheck",      # 配置类型(请前端写死)
        "optionKey":"apiVoiceCheck"     # 配置的key(请前端写死)
     */
    async getCheckList () {
        const res = await Request.axiosInstance.post('/web/user-options/get-option-key-value', {
            optionType: "voiceCheck",
            optionKey: "apiVoiceCheck"
        })
        return res
    }
}

export default {
    _apiRuleService: new ApiRuleService()
}